import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';

const Swap = () => {
  const { token } = useUser();
  const [swaps, setSwaps] = useState([]);
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    fetchSwaps();
  }, [token]);

  const fetchSwaps = async () => {
    try {
      const response = await axios.get(
        "https://garden-api.mydreamstudio.workers.dev/me/swaps",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setUser(response.data.user);
      setSwaps(response.data.swaps);
    } catch (err) {
      console.error(err);
    }
  };

  const openModal = (type) => {
    setModalType(type);
    setModalOpen(true);
    setInputValue('');
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalType('');
    setInputValue('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would handle the submission based on the modalType
    console.log(`Submitting ${modalType} with value: ${inputValue}`);
    // Add your API call or state update logic here
    closeModal();
  };

  return (
    <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
      {/* first section */}
      {user && (
      <div className="bg-[#f4ebcb] p-6 rounded-lg shadow-md mb-0">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2 flex items-center">
              <img src="/images/gold-icon.png" alt="Gold Icon" className="w-6 h-6 mr-2" />
              Gold
            </h2>
            <p className="text-green-600">{user.gold}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2 flex items-center">
              <img src="/images/silver-icon.png" alt="Silver Icon" className="w-6 h-6 mr-2" />
              Silver
            </h2>
            <p className="text-green-600">{user.silver}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2 flex items-center">
              <img src="/images/water-icon.png" alt="Aqua Icon" className="w-6 h-6 mr-2" />
              Aqua
            </h2>
            <p className="text-green-600">{user.aqua}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2 flex items-center">
              <img src="/images/sun-icon.png" alt="Sun Icon" className="w-6 h-6 mr-2" />
              Sun
            </h2>
            <p className="text-green-600">{user.sun}</p>
          </div>
        </div>
      </div>
      )}

      <div className="grid grid-cols-3 gap-0 mt-0">
        <button
          onClick={() => openModal('buy')}
          className="bg-green-700 text-white py-2 px-4 rounded-l-lg hover:bg-green-800 transition-colors text-center"
        >
          Buy
        </button>
        <button
          onClick={() => openModal('swap')}
          className="bg-blue-600 text-white py-2 px-4 hover:bg-blue-700 transition-colors text-center"
        >
          Swap
        </button>
        <button
          onClick={() => openModal('sell')}
          className="bg-red-600 text-white py-2 px-4 rounded-r-lg hover:bg-red-700 transition-colors text-center"
        >
          Sell
        </button>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white p-8 rounded-lg w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-6 capitalize">
              {modalType === 'buy' && 'Buy SILVER'}
              {modalType === 'swap' && 'Swap SILVER for GOLD'}
              {modalType === 'sell' && 'Sell GOLD'}
            </h2>
            <form onSubmit={handleSubmit}>
              {modalType === 'sell' ? (
                <textarea
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="w-full p-3 mb-6 border rounded text-lg"
                  placeholder="Enter LNURL"
                  rows="4"
                />
              ) : (
                <input
                  type="number"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="w-full p-3 mb-6 border rounded text-lg"
                  placeholder={`Enter amount to ${modalType}`}
                />
              )}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-6 py-3 bg-gray-200 rounded text-lg hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-3 bg-blue-500 text-white rounded text-lg hover:bg-blue-600 transition-colors"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* second section */}
      <div className="bg-white p-6 rounded-lg shadow-md mt-4">
        <h2 className="text-2xl font-semibold text-green-800 mb-4">Recent Transactions</h2>
        {swaps.length === 0 ? (
          <p className="text-gray-600">No transactions available.</p>
        ) : (
          <ul className="space-y-4">
            {swaps.map((swap) => (
              <li key={swap.id} className="border-b pb-4 last:border-b-0">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-green-700">{swap.fromVegetable} → {swap.toVegetable}</p>
                    <p className="text-sm text-gray-600">Amount: {swap.amount}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-600">{new Date(swap.date).toLocaleString()}</p>
                    <p className={`font-semibold ${swap.status === 'completed' ? 'text-green-600' : 'text-yellow-600'}`}>
                      {swap.status}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Swap;