import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './UserContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import Dashboard from './pages/Dashboard';
import Telegram from './pages/Telegram';
// Add imports for new pages
import CollectionDetail from './pages/CollectionDetail';
import ItemDetail from './pages/ItemDetail';
import Minter from './pages/Minter';
import Garden from './pages/Garden';
import Bridge from './pages/Bridge';
import BridgeDetail from './pages/BridgeDetail';
import Profile from './pages/Profile';
import ItemPerp from './pages/ItemPerp';
import Market from './pages/Market';
import Treasure from './pages/Treasure';
import Planter from './pages/Planter';
import GardenDetail from './pages/GardenDetail';
import UserDetail from './pages/UserDetail';
import TwitterLogin from './pages/TwitterLogin';
import TwitterCallback from './pages/TwitterCallback';
import Oracle from './pages/Oracle';    
import ContractDetail from './pages/ContractDetail';
import SwapDetail from './pages/SwapDetail';  
import Swap from './pages/Swap';

import ResourceBar from './components/ResourceBar';
import BottomNavBar from './components/BottomNavBar';



const App = () => {

  

  useEffect(() => {

    const tgWebApp = window.Telegram?.WebApp;
    if (tgWebApp) {
      tgWebApp.expand();
    }

    // Set up the interval and store the interval ID
    // const intervalId = setInterval(updateUser, 60000);

    // // Cleanup function
    // return () => {
    //   // Clear the interval when the component unmounts
    //   clearInterval(intervalId);
    // };

  }, []);

  const authenticateUser = () => {
    const token = localStorage.getItem('token');
    if (token) { return true; } else { return false; }
  };

  const updateUser = async () => {
    const token = localStorage.getItem('token');
    if (token) { await fetchUserData(token); } else { return false; }
  }

  const fetchUserData = async (token) => {
    console.log(`fetchUserData: ${token}`);
  }

  const PrivateRoute = ({ children }) => {
    //const {milliSeed, milliGold, milliSun, milliAqua} = getMilliResources()
    //console.log(`milliSeed: ${milliSeed}, milliGold: ${milliGold}, milliSun: ${milliSun}, milliAqua: ${milliAqua}`);
    return authenticateUser() ? (
      <>

        <ResourceBar />

        <div className="mt-8">
          {/* This div creates space between the top navigation and the main content */}
          {/* The mt-16 class adds margin to the top, pushing content below the fixed top nav */}
          {/* The mb-20 class adds margin to the bottom, creating space above the fixed bottom nav */}
        </div>

        {children}

        <ToastContainer />


        <div className="mb-20">
          {/* This div creates space between the top navigation and the main content */}
          {/* The mt-16 class adds margin to the top, pushing content below the fixed top nav */}
          {/* The mb-20 class adds margin to the bottom, creating space above the fixed bottom nav */}
        </div>

        <BottomNavBar />
        
      </>
    ) : <Navigate to="/tma" />;
  };
  return (
    <TonConnectUIProvider manifestUrl="https://bubble-garden-app.pages.dev/tonconnect-manifest.json">
      <UserProvider>
        <Router>
          <div className="min-h-screen bg-background text-foreground" >
            <Routes>
              <Route path="/" element={authenticateUser() ? <Navigate to="/dashboard" /> : <Telegram />} />
              <Route path="/tma" element={<Telegram />} />
              <Route path="/twitter/login" element={<TwitterLogin />} />
              <Route path="/twitter/callback" element={<TwitterCallback />} />

              {/* Wrap authenticated routes with PrivateRoute */}
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/market" element={<PrivateRoute><Market /></PrivateRoute>} />
              {/* Add new routes */}
              <Route path="/collections/:collectionId" element={<PrivateRoute><CollectionDetail /></PrivateRoute>} />
              <Route path="/items/:itemId" element={<PrivateRoute><ItemDetail /></PrivateRoute>} />
              <Route path="/items/:itemId/perp" element={<PrivateRoute><ItemPerp /></PrivateRoute>} />

              <Route path="/minter" element={<PrivateRoute><Minter /></PrivateRoute>} />
              <Route path="/bridge" element={<PrivateRoute><Bridge /></PrivateRoute>} />
              <Route path="/bridge/:type/:id" element={<PrivateRoute><BridgeDetail /></PrivateRoute>} />
              <Route path="/treasure" element={<PrivateRoute><Treasure /></PrivateRoute>} />
              <Route path="/planter" element={<PrivateRoute><Planter /></PrivateRoute>} />
              <Route path="/garden" element={<PrivateRoute><Garden /></PrivateRoute>} />
              <Route path="/gardens/:id" element={<PrivateRoute><GardenDetail /></PrivateRoute>} />
              <Route path="/users/:id" element={<PrivateRoute><UserDetail /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path="/swap" element={<PrivateRoute><Swap /></PrivateRoute>} />
              <Route path="/swap/:id" element={<PrivateRoute><SwapDetail /></PrivateRoute>} />
              <Route path="/oracle" element={<PrivateRoute><Oracle /></PrivateRoute>} />
              <Route path="/contracts/:id" element={<PrivateRoute><ContractDetail /></PrivateRoute>} />
            </Routes>
          </div>
        </Router>
      </UserProvider>
    </TonConnectUIProvider>

  );
};

export default App;
