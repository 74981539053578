import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { QRCodeSVG } from 'qrcode.react';

import { useUser } from '../UserContext';

const BridgeDetail = () => {
    const { type, id } = useParams();
    const { token } = useUser();
    const [purchaseData, setPurchaseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                const response = await axios.get(
                    `https://garden-api.mydreamstudio.workers.dev/bridges/${type}/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setPurchaseData(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch transaction details');
                setLoading(false);
            }
        };

        fetchTransactionDetails();
    }, [id, token]);

    return (
        <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
            <div className="max-w-3xl mx-auto">
                <Link to="/profile" className="block mt-2 mb-4 bg-orange-500 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-colors shadow-md text-center">
                    Back to Profile
                </Link>



                {purchaseData && (
                    <>
                        {purchaseData && purchaseData.status === 'pending' && purchaseData.paymentRequest &&(
                            <div className="rounded-lg shadow-md">
                                <div className="flex justify-center">
                                    <QRCodeSVG
                                        value={purchaseData.paymentRequest}
                                        size={256}
                                        level={'H'}                                        
                                    />
                                </div>
                            </div>
                        )}

                        {/* second section */}
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <h3 className="text-xl font-semibold text-green-800 mb-2">Status</h3>
                                    <p className="text-green-600">{purchaseData.status}</p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-green-800 mb-2">SEED</h3>
                                    <p className="text-green-600">{purchaseData.milliSeed / 1000}</p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold text-green-800 mb-2">Payment Hash</h3>
                                <p className="text-green-600 break-all">{purchaseData.paymentHash}</p>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold text-green-800 mb-2">Payment Request</h3>
                                <p className="text-green-600 break-all">{purchaseData.paymentRequest}</p>
                            </div>                
                        </div>
                        {purchaseData && purchaseData.paymentRequest && (
                            <div className="mt-4">
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(purchaseData.paymentRequest);
                                        toast.success('Payment request copied to clipboard!', {
                                            position: "bottom-center"
                                        });
                                    }}
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                >
                                    Copy Lightning Invoice
                                </button>
                            </div>
                        )}
                    </>

                )}
            </div>
        </div>
    );
};

export default BridgeDetail;