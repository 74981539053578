import React, { useState, useEffect} from 'react';
import { useUser } from '../UserContext';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Garden = () => {
    const { token } = useUser();
    const [activeTab, setActiveTab] = useState('personal');
    const [myGardens, setMyGardens] = useState([]);
    const [myInvestments, setMyInvestments] = useState([]);
    const [plantAmount, setPlantAmount] = useState(0);
    const [valueInDollar, setValueInDollar] = useState(0);

    const fetchGardens = async () => {
        try {
            console.log(token);
          const response = await axios.get(
            "https://garden-api.mydreamstudio.workers.dev/me/gardens",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setMyGardens(response.data.myGardens);
          setMyInvestments(response.data.myInvestments);
          console.log(response.data);
        } catch (err) {
          console.error(err);
        }
      };    

      useEffect(() => {
        fetchGardens();
      }, [token]);

    return (
        <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
            {/* first section
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h2 className="text-xl font-semibold text-green-800 mb-2">Garden</h2>
                        <p className="text-green-600">{plantAmount}</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-semibold text-green-800 mb-2">Value</h2>
                        <p className="text-green-600">{valueInDollar}</p>
                    </div>
                </div>
            </div> */}

            {/* second section */}
            <div className="mb-6">
                <div className="flex space-x-4">
                    {['personal', 'friends'].map((tab) => (
                        <button
                            key={tab}
                            className={`px-4 py-2 rounded-full transition-colors shadow-md capitalize ${
                                activeTab === tab
                                    ? 'bg-green-500 text-white'
                                    : 'bg-white text-green-500 hover:bg-green-100'
                            }`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>

            {/* Content based on active tab */}
            <div className="">
                {activeTab === 'personal' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {myGardens.length === 0 ? (
                            <Link to="/planter" className="col-span-full">
                                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
                                    <div className="p-6 text-center">
                                        <h3 className="text-xl font-semibold text-green-800 mb-2">Start Your First Garden</h3>
                                        <p className="text-gray-600 mb-4">Begin your gardening journey today!</p>
                                        <button className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-colors duration-300">
                                            Create a Garden
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            myGardens.map((garden) => (
                                <Link key={garden.id} to={`/gardens/${garden.id}`} className="block">
                                    <div className="bg-white rounded-lg shadow-md overflow-hidden flex hover:shadow-lg transition-shadow duration-300">
                                        <div className="p-4 flex-1">
                                            <h3 className="text-lg font-semibold text-green-800 mb-2">{garden.name}</h3>
                                            <div className="grid grid-cols-2 gap-2 text-sm">
                                                <div>
                                                    <p className="font-medium text-gray-600">Value</p>
                                                    <p>$-</p>
                                                </div>
                                                <div>
                                                    <p className="font-medium text-gray-600">Vegetables</p>
                                                    <p>0</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-1/2 relative">
                                            <img 
                                                src={garden.iconUrl} 
                                                alt={garden.name} 
                                                className="absolute top-0 left-0 w-full h-full object-cover"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = 'https://via.placeholder.com/300x169?text=Garden+Image';
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                )}
                {activeTab === 'friends' && (
                    <>
                    </>
                )}
            </div>
        </div>
    );
};

export default Garden;