import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUser } from '../UserContext';
import axios from 'axios';

const Bridge = () => {
    const { token } = useUser();
    const navigate = useNavigate();
    const [isBuyState, setIsBuyState] = useState(true);
    const [tonAmount, setTonAmount] = useState('');
    const [tonBalance, setTonBalance] = useState(0);
    const [btcAmount, setBtcAmount] = useState('');
    const [btcBalance, setBtcBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [price, setPrice] = useState(0);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const type = searchParams.get('type');
        //console.log(type);
        if (type === 'buy') {
            setIsBuyState(true);
        } else {
            setIsBuyState(false);
        }
    

        fetchPrices();
        fetchTransactions();

    }, [location.pathname]);

    const handleTonAmountChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 10_000)) {
            setTonAmount(value);
            setBtcAmount(value * price / 100_000_000); // Example conversion, replace with actual logic
        }
    };

    const handleTransaction = async (e) => {

        try {
            e.preventDefault();
            //console.log(e);
            //console.log(`${isBuyState ? 'Buying' : 'Selling'} ${tonAmount} TON`);
            let inOrOut = isBuyState ? 'in' : 'out';
            let url = `https://garden-api.mydreamstudio.workers.dev/bridges/${inOrOut}`;
            const response = await axios.post(url, {
                amount: tonAmount,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.data) {
                let inOrOut = isBuyState ? 'in' : 'out';
                let url = `/bridge/${inOrOut}/${response.data.id}`;
                navigate(url, { state: { purchaseData: response.data } });
            }
            //console.log(response.data);
        } catch (err) {
            //console.log(err.response.data.error);
            toast.error(`${err.response.data.error}`, {
                position: "bottom-center"
            });
        }
    };


    const fetchPrices = async () => {
        try {
            
            const response = await axios.get(
                "https://garden-api.mydreamstudio.workers.dev/prices/now",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const btcusd = response.data.btcusd;
            //const tonusd = response.data.tonusd;

            let price = 1 / btcusd * 100_000_000;
            const searchParams = new URLSearchParams(location.search);
            const type = searchParams.get('type');
            if (type === 'buy') {
                price = parseInt(price * 1.05);
            } else {
                price = parseInt(price * 0.95);
            }
            //console.log(price);
            setPrice(price);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchTransactions = async () => {
        try {
            let url = '';
            const searchParams = new URLSearchParams(location.search);
            const type = searchParams.get('type');
            if (type === 'buy') {
                url = `https://garden-api.mydreamstudio.workers.dev/bridges/in`;
            } else {
                url = `https://garden-api.mydreamstudio.workers.dev/bridges/out`;
            }            
            const response = await axios.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            //console.log(response.data);
            setTransactions(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
            <div className="max-w-3xl mx-auto">
                <Link to="/treasure" className="block mt-2 mb-4 bg-orange-500 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-colors shadow-md text-center">
                    Back to Treasure
                </Link>
                {/* Input and Output section */}
                <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                    <div className="mb-4">
                        <label htmlFor="input" className="block text-green-700 mb-2">SEED</label>
                        <input
                            type="number"
                            id="input"
                            value={tonAmount}
                            onChange={handleTonAmountChange}
                            min="1"
                            max="999"
                            className="w-full px-3 py-2 border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="output" className="block text-green-700 mb-2">BTC</label>
                        <input
                            type="number"
                            id="output"
                            value={btcAmount}
                            readOnly
                            className="w-full px-3 py-2 border border-green-300 rounded-md bg-green-50"
                        />
                    </div>
                </div>

                {/* Transaction button */}
                <button
                    onClick={handleTransaction}
                    className={`w-full py-2 px-4 rounded-full text-white transition-colors ${isBuyState ? 'bg-green-700 hover:bg-green-800' : 'bg-red-600 hover:bg-red-700'
                        }`}
                >
                    {isBuyState ? 'Buy SEED' : 'Sell SEED'}
                </button>

                {/* Transaction Cards */}
                {transactions.length > 0 && (
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold text-orange-500 mb-4">{isBuyState ? 'Recent SEED Purchases' : 'Recent SEED Sales'}</h2>
                        <div className="space-y-4">
                            {transactions.map((transaction, index) => (
                                <Link 
                                    key={index} 
                                    to={{
                                        pathname: `/bridge/${isBuyState ? 'in' : 'out'}/${transaction.id}`,
                                    }}
                                    className="block bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                                >
                                    <div>
                                        <p className="text-lg font-semibold text-green-700">
                                            {isBuyState ? 'Bought' : 'Sold'} {Math.floor(transaction.milliSeed / 1_000)} SEED
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            {new Date(transaction.createdAt).toLocaleString()}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Status: {transaction.status}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bridge;