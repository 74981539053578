import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const Planter = () => {
    const { token } = useUser();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('collection');
    const [itemFormData, setItemFormData] = useState({
        name: '',
        description: '',
        image: null
    });
    const [collectionFormData, setCollectionFormData] = useState({
        name: '',
        description: '',
        image: null
    });

    const handleInputChange = (formType, e) => {
        const { name, value, type, files } = e.target;
        const setFormData = formType === 'item' ? setItemFormData : setCollectionFormData;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));
    };

    const handleSubmit = async (formType, e) => {
        e.preventDefault();
        // Handle form submission here
        const formData = new FormData();
        const data = formType === 'item' ? itemFormData : collectionFormData;

        // Append form fields to FormData
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        try {
            const endpoint = 'https://garden-api.mydreamstudio.workers.dev/gardens';
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const gardenId = await response.data.id;            
            toast.success('Garden created!', {
                position: "bottom-center"
            });
            setTimeout(()=> {
                navigate(`/gardens/${gardenId}`);
            }, 1_000);
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const renderForm = (formType) => {
        const formData = formType === 'item' ? itemFormData : collectionFormData;

        return (
            <form onSubmit={(e) => handleSubmit(formType, e)}>
                <div className="bg-white p-6 rounded-lg shadow-md border-2 border-green-600">
                    <div className="mb-4">
                        <label htmlFor={`${formType}-name`} className="block text-sm font-medium text-green-700 mb-2">Name</label>
                        <input
                            type="text"
                            id={`${formType}-name`}
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleInputChange(formType, e)}
                            className="mt-1 block w-full rounded-md border-2 border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500 bg-green-50 text-green-800 px-4 py-2"
                            placeholder="e.g. Pixel Sunflower"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor={`${formType}-description`} className="block text-sm font-medium text-green-700 mb-2">Description</label>
                        <textarea
                            id={`${formType}-description`}
                            name="description"
                            value={formData.description}
                            onChange={(e) => handleInputChange(formType, e)}
                            rows="3"
                            className="mt-1 block w-full rounded-md border-2 border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500 bg-green-50 text-green-800 px-4 py-2"
                            placeholder="A vibrant pixel art sunflower that brightens up any digital garden!"
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label htmlFor={`${formType}-image`} className="block text-sm font-medium text-green-700 mb-2">Image</label>
                        <input
                            type="file"
                            id={`${formType}-image`}
                            name="image"
                            onChange={(e) => handleInputChange(formType, e)}
                            className="mt-1 block w-full text-green-700 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-4 w-full px-4 py-3 rounded-md transition-colors shadow-md bg-green-600 text-white hover:bg-green-700 font-semibold text-lg"
                >
                    {formType === 'item' ? 'Plant Item' : 'Start Garden'}
                </button>
            </form>
        );
    };

    return (
        <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
            <div className="mb-6">
                {/* <div className="flex flex-wrap gap-2 mb-6">
                    <button
                        className={`px-4 py-2 rounded-full transition-colors shadow-md capitalize
                        ${activeTab === 'item' ? 'bg-green-600 text-white' : 'bg-white text-green-600'}`}
                        onClick={() => setActiveTab('item')}
                    >
                        Plant
                    </button>
                    <button
                        className={`px-4 py-2 rounded-full transition-colors shadow-md capitalize
                        ${activeTab === 'collection' ? 'bg-green-600 text-white' : 'bg-white text-green-600'}`}
                        onClick={() => setActiveTab('collection')}
                    >
                        Garden
                    </button>
                </div> */}
                {activeTab === 'item' ? renderForm('item') : renderForm('collection')}
            </div>
        </div>
    );
};

export default Planter;