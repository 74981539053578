import React from 'react';

const ItemDetail = () => {
  return (
    <div>
      <h1>Item Page</h1>
      <p>This is a placeholder for the Item page.</p>
    </div>
  );
};

export default ItemDetail;