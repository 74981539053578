import React from 'react';

const Oracle = () => {
  return (
    <div>
      <h1>Collection Page</h1>
      <p>This is a placeholder for the Collection page. put bitcoin price, and contract details...</p>
    </div>
  );
};

export default Oracle;