import React from 'react';

const Minter = () => {
  return (
    <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/background-all.jpg)` }}>
      <h1>Minter Page</h1>
      <p>This is a placeholder for the Minter page.</p>
    </div>
  );
};

export default Minter;