import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useUser } from '../UserContext';

const GardenDetail = () => {
    const { id } = useParams();
    const { token } = useUser();
    const [garden, setGarden] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchGardenDetail();
    }, [id]);

    const fetchGardenDetail = async () => {
        try {
            const response = await axios.get(`https://garden-api.mydreamstudio.workers.dev/gardens/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setGarden(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch garden details');
            setLoading(false);
        }
    };
    
    const fetchGardenLink = async () => {
        try {
            const response = await axios.get(`https://garden-api.mydreamstudio.workers.dev/gardens/${id}/share`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("response", response.data);
            const url = `https://t.me/BubbleGardenBot/trade?startapp=${response.data}`;
            const message = `Check out this garden: ${url}`;
            //const telegramShareUrl = `https://t.me/share/url?url=${url}&text=${encodeURIComponent('Check out this garden!')}`;
            window.Telegram.WebApp.switchInlineQuery(message, ['users', 'groups', 'channels']);
            //window.open(telegramShareUrl, '_blank');
    
            toast.success('Garden link has been shared!', {
                position: "bottom-center"
            });
        }
        catch (err) {
            setError('Failed to fetch garden link');
            toast.error('Failed to fetch garden link', {
                position: "bottom-center"
            });               
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!garden) return <div>No garden found</div>;

    return (
        <div className="container mx-auto px-4 py-8" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>            
            {/* First section: Garden image */}
            <div className="mb-8">
                <img src={garden.iconUrl} alt={garden.name} className="w-full h-64 object-cover rounded-lg" />
            </div>

            {/* Second section: Garden info and share button */}
            <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
                <h1 className="text-3xl font-bold mb-4">{garden.name}</h1>
                <p className="mb-2">{garden.description}</p>
                <p className="mb-2">Creator: {garden.creator}</p>
                <div className="flex justify-between mb-4">
                    <span>Sun: {garden.sun}</span>
                    <span>Aqua: {garden.aqua}</span>
                    <span>Gold: {garden.gold}</span>
                    <span>Silver: {garden.silver}</span>
                </div>
                <button 
                    onClick={fetchGardenLink}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Share Garden Link
                </button>
            </div>

            {/* Third section: 3x3 grid of plots */}
            <div className="mb-8 grid grid-cols-3 gap-4">
                {[...Array(9)].map((_, index) => (
                    <div key={index} className="relative">
                        <img src={`/images/plot_${index + 1}.jpg`} alt={`Plot ${index + 1}`} className="w-full h-32 object-cover rounded-lg" />
                        <button className="absolute bottom-2 right-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-sm">
                            
                            Plant/Harvest
                        </button>
                    </div>
                ))}
            </div>

            {/* Fourth section: List of planted vegetables */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Planted Vegetables</h2>
                {garden.planted_vegetables && garden.planted_vegetables.length > 0 ? (
                    <ul>
                        {garden.planted_vegetables.map((vegetable, index) => (
                            <li key={index} className="mb-2">
                                {vegetable.name} - Planted on: {new Date(vegetable.planted_date).toLocaleDateString()}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No vegetables planted yet.</p>
                )}
            </div>
        </div>
    );
};

export default GardenDetail;