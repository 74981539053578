import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../UserContext";

const UserDetail = () => {
    const { id } = useParams();
    const { token } = useUser();
    const [user, setUser] = useState(null);
    const [gardens, setGardens] = useState([]);
    const [vegetables, setVegetables] = useState([]);
    const [friends, setFriends] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/login");
        } else {
            fetchUserData();
        }
    }, [token, navigate]);

    const fetchUserData = async () => {
        try {
            const response = await axios.get(
                `https://garden-api.mydreamstudio.workers.dev/users/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(response.data);
            setUser(response.data.user);
            setGardens(response.data.gardens);
            setVegetables(response.data.vegetables);
            // setFriends(response.data.friends);
        } catch (err) {
            setError("Failed to fetch UserDetail data");
            
        }
    };


    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div className="min-h-screen p-4" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>

        {{user}}
        </div>
    );
};

export default UserDetail;