import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Market = () => {
    const { token } = useUser();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [plants, setPlants] = useState([]);
    const [filteredPlants, setFilteredPlants] = useState([]);
    const [activeFilter, setActiveFilter] = useState('seed');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate("/login");
        } else {
            fetchMarketData();
        }
    }, [token, navigate]);

    const fetchMarketData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get('https://garden-api.mydreamstudio.workers.dev/me/market', {
                headers: { Authorization: `Bearer ${token}` }
            });
            const plantsData = Array.isArray(response.data) ? response.data : [];
            setPlants(plantsData);
            setFilteredPlants(plantsData);
        } catch (err) {
            console.error("Error fetching market data:", err);
            setError("Failed to fetch market data. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const filterPlants = (status) => {
        setActiveFilter(status);
        if (status === 'all') {
            setFilteredPlants(plants);
        } else {
            setFilteredPlants(plants.filter(plant => plant.status === status));
        }
    };

    if (isLoading) {
        return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
    }

    if (error) {
        return <div className="min-h-screen flex items-center justify-center text-red-500">{error}</div>;
    }

    const filterButtons = ['seed', 'sprout', 'mature', 'dead'];

    return (
        <div className="min-h-screen p-4" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>

            {/* First section: Full-scale image */}
            <div className="mb-6 rounded-lg overflow-hidden shadow-md">
                <img
                    src="https://bubble-garden-app.pages.dev/images/thriving-garden.jpg"
                    alt="Garden Overview"
                    className="w-full h-auto"
                />
            </div>

            {/* Second section: Button group */}
            <div className="flex flex-wrap gap-2 mb-6">
                {filterButtons.map((status) => (
                    <button
                        key={status}
                        className={`px-4 py-2 rounded-full transition-colors shadow-md capitalize
              ${activeFilter === status ? 'bg-green-600 text-white' : 'bg-white text-green-600'}`}
                        onClick={() => filterPlants(status)}
                    >
                        {status}
                    </button>
                ))}
            </div>

            {/* Third section: List of plants */}
            <div className="space-y-4">
                {filteredPlants.length > 0 ? (
                    filteredPlants.map((plant) => (
                        <div key={plant.id} className="flex items-center bg-white p-4 rounded-lg shadow-md">
                            <div className="flex-grow">
                                <h3 className="text-lg font-semibold text-green-800">{plant.name}</h3>
                                <p className="text-green-600">{plant.description}</p>
                                <p className="text-green-500">Status: {plant.status}</p>
                            </div>
                            <img src={plant.image} alt={plant.name} className="w-24 h-24 rounded-lg ml-4" />
                        </div>
                    ))
                ) : (
                    <div className="text-center py-8 bg-white rounded-lg shadow-md">
                        <p className="text-gray-600">No plants available in this category.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Market;