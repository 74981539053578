import React, { useEffect, useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../UserContext';
import { useTonConnectUI, TonConnectButton } from '@tonconnect/ui-react';
import axios from 'axios';

const Profile = () => {
  const { token } = useUser();
  const [user, setUser] = useState(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tonConnectUI] = useTonConnectUI();
  const [walletAddress, setWalletAddress] = useState(null);

  const fetchUser = useCallback(async () => {
    if (!token) {
      setLoading(false);
      setError("No authentication token available");
      return;
    }

    try {
      const response = await axios.get(
        "https://garden-api.mydreamstudio.workers.dev/me/profile",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('response.data', response.data);
      setUser(response.data.user);
      setFollowerCount(response.data.followerCount);
      setFollowingCount(response.data.followingCount);
      setError(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {

    const checkWalletConnection = () => {
      const isConnected = tonConnectUI.connected;
      if (isConnected) {
        const address = tonConnectUI.account.address;
        setWalletAddress(address);

        setTimeout(() => {
          if (!user.tonRawAddress) {
            updateTonAddress(address);
          } else {
            console.log('wallet already connected');
          }
        }, 5_000)


      } else {
        setWalletAddress(null);
      }
    };

    checkWalletConnection();
    tonConnectUI.onStatusChange(checkWalletConnection);

  }, [tonConnectUI]);

  const handleTwitterLogin = () => {
    const url = 'https://bubble-garden-app.pages.dev/twitter/login?token=' + token;
    window.open(url, '_blank')
  };

  const updateTonAddress = async (address) => {
    const response = await axios.post(
      "https://garden-api.mydreamstudio.workers.dev/me/ton",
      {
        address: address,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      toast.success(`TON wallet connected`, {
        position: "bottom-center"
      });
    }
  };

  const handleTonConnect = async () => {
    if (walletAddress) {
      await tonConnectUI.disconnect();
    } else {
      await tonConnectUI.openModal();
    }
  };

  if (loading) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>Error: {error}</div>;
  }

  if (!user) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>No user data available</div>;
  }

  return (
    <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
      {/* First section: Container/card */}
      <div className="bg-[#f4ebcb] p-6 rounded-lg shadow-md">
        <div className="grid grid-cols-2 gap-4">

          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">TON Wallet</h2>
            <p className="text-green-600">
              {walletAddress
                ? `Connected`
                : "Disconnected"}
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">X Account</h2>
            <p className="text-green-600">{user.twitterUsername
              ? `@${user.twitterUsername}`
              : "Disconnected"}</p>
          </div>

          {/* <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">Following</h2>
            <p className="text-green-600">{followingCount}</p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">Follower</h2>
            <p className="text-green-600">{followerCount}</p>
          </div> */}

          {/* <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">Profile</h2>
            <p className="text-green-600">@{user.telegramUsername}</p>
          </div>                   */}

        </div>

      </div>

      {/* Buttons */}
      <div className="flex">
        {/* Custom Ton Connect Button */}
        <button
          onClick={handleTonConnect}
          className="flex-1 bg-blue-400 text-white py-2 px-4 rounded-l-lg hover:bg-blue-500 transition-colors"
        >
          {walletAddress
            ? `Disconnect TON`
            : "Connect TON"}
        </button>

        {/* Twitter Login Button */}
        <button
          onClick={() => handleTwitterLogin()}
          className="flex-1 bg-black text-white py-2 px-4 rounded-r-lg hover:bg-gray-800 transition-colors"
        >
          {user.twitterUsername
            ? "Reconnect X"
            : "Connect X"}
        </button>
      </div>

    </div>

  );
};

export default Profile;