import React, { useCallback, useEffect, useState } from "react";
import { useUser } from "../UserContext";
import axios from "axios";
import { Link } from "react-router-dom";

const Treasure = () => {

  const { token } = useUser();
  const [user, setUser] = useState(null);
  const [completedRewards, setCompletedRewards] = useState([]);
  const [pendingRewards, setPendingRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const fetchRewards = useCallback(async () => {
    if (!token) {
      setLoading(false);
      setError("No authentication token available");
      return;
    }

    try {
      const response = await axios.get(
        "https://garden-api.mydreamstudio.workers.dev/me/rewards",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('response.data', response.data);
      setUser(response.data.user);
      setError(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  if (loading) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>Error: {error}</div>;
  }

  if (!user) {
    return <div style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>No user data available</div>;
  }

  return (
    <div className="min-h-screen p-4 bg-green-50" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background-all.jpg)` }}>
      {/* First section: Treasure Overview */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-0">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">SEED</h2>
            <p className="text-green-600">{(user.milliSeed / 1000).toFixed(2)}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">SUN</h2>
            <p className="text-green-600">{(user.milliSun / 1000).toFixed(2)}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-green-800 mb-2">AQUA</h2>
            <p className="text-green-600">{(user.milliAqua / 1000).toFixed(2)}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-0 mt-0">
        <Link
          to="/bridge?type=buy"
          className="bg-green-700 text-white py-2 px-4 rounded-l-lg hover:bg-green-800 transition-colors text-center"
        >
          Buy SEED
        </Link>
        <Link
          to="/bridge?type=sell"
          className="bg-red-600 text-white py-2 px-4 rounded-r-lg hover:bg-red-700 transition-colors text-center"
        >
          Sell SEED
        </Link>
      </div>

      {/* Second section: List of treasures */}

    </div>
  );
};

export default Treasure;